// global.api_url = 'http://184.168.125.152/plesk-site-preview/99solutions.co.in/https/184.168.125.152/Express/DEV2/zship'
//global.api_url = 'https://zship.in/TEST_ZSHIP_BETA/zship'
//global.ndr_upload_format = 'https://zship.in/TEST_ZSHIP_BETA/zship/upload/format/ndr_upload.xlsx'
//global.bulk_upload_format = 'https://zship.in/TEST_ZSHIP_BETA/zship/upload/format/uploadfile_bulk.csv'
//global.upload_resp_url = 'https://betaorders.zship.in/storage/app'
//global.trackingid_upload_format = 'https://zship.in/TEST_ZSHIP_BETA/zship/upload/format/trackingid_upload.csv'
//global.pincode_url = 'https://betapostalcodes.zship.in/public'

global.api_url = 'https://zship.in/ZshipExp/PHP/zship'
global.ndr_upload_format = 'https://zship.in/ZshipExp/PHP/zship/upload/format/ndr_upload.xlsx'
global.bulk_upload_format = 'https://zship.in/ZshipExp/PHP/zship/upload/format/uploadfile_bulk.csv'
global.upload_resp_url = 'https://orders.zship.in/storage/app'
global.trackingid_upload_format = 'https://zship.in/ZshipExp/PHP/zship/upload/format/trackingid_upload.csv'
global.pincode_url = 'https://postalcodes.zship.in/public'

