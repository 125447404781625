import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Route, HashRouter } from 'react-router-dom';
import 'src/components/globalVariables.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './helper.scss';
import 'src/pages/color_var.scss';
// import { RoutesFinal } from "src/routes/zship-routes";

const App = () => {
  const routes = require('src/routes/zship-routes').default;
  const [themeColor, setThemeColor] = useState<string>("purple");
  const selectRef: any = useRef<HTMLSelectElement>();
  // console.log("HIII", require('src/routes/zship-routes').default);
  // const userStr = localStorage.getItem("user");
  // const errorPage = React.lazy(() => import('src/pages/error/error'));
  // const loginPage = React.lazy(() => import('src/pages/login/container'));

  // const routeComponents = routes.map((route, key) => {
  //   return (<Route path={route.path} key={key} render={props => (
  //     userStr ? <route.component {...props} routes={route.routes} /> : <><route.component routes={[{ path: '/error', component: errorPage }, { path: '/login', component: loginPage }]} /> <Redirect to="/login" /></>
  //   )} />)
  // });

  const routeComponents = routes.map((route, key) => {
    return (<Route exact path={route.path} key={key} render={props => (
      <route.component {...props} routes={route.routes} />)} />)
  });

  const updateColorTheme = (e: any) => {
    let el = e.target;
    setThemeColor(prevState => {
      return el.value;
    });
  }

  useEffect(() => {
    const root = document.documentElement;
    let bkgColor, light, slightLight, lighter, fontColor, bkgImg;
    switch (themeColor) {
      case 'purple':
        // bkgColor = '#adb8ef';
        bkgColor = '#ffffff';
        light = '#242b4c';
        lighter = '#505faa';
        slightLight = '#2c355e';
        fontColor = '#ffff';
        bkgImg = '#adb8ef';
        // bkgImg = 'url("lightpurple.png")'
        break;
      case 'green':
        // bkgColor = '#9fdfb3';
        bkgColor = '#ffffff';
        light = '#053715';
        lighter = '#12b446';
        slightLight = '#074a1d';
        fontColor = '#ffff';
        bkgImg = '#9fdfb3';
        // bkgImg = 'url("lightgreen.png")'
        break;
      case 'red':
        // bkgColor = '#e1a6b4';
        bkgColor = '#ffffff';
        light = '#81112c';
        lighter = '#c41a44';
        slightLight = '#540f20';
        fontColor = '#ffff';
        bkgImg = '#e1a6b4';
        // bkgImg = 'url("lightred.png")'
        break;
      case 'blue':
        // bkgColor = '#add4f3';
        bkgColor = '#ffffff';
        light = '#25669a';
        lighter = '#3b8ed0';
        slightLight = '#245175';
        fontColor = '#ffff';
        bkgImg = '#add4f3';
        // bkgImg = 'url("lightblue.png")'
        break;

      default:
        // bkgColor = '#adb8ef';
        bkgColor = '#ffffff';
        light = '#242b4c';
        lighter = '#505faa';
        slightLight = '#2c355e';
        fontColor = '#ffff';
        bkgImg = '#adb8ef';
        // bkgImg = 'url("lightpurple.png")'
        break;
    }
    root?.style.setProperty("--background-color", bkgColor);
    root?.style.setProperty("--light", light);
    root?.style.setProperty("--lighter", lighter);
    root?.style.setProperty("--slightLight", slightLight);
    root?.style.setProperty("--fontColor", fontColor);
    root?.style.setProperty("--bkgImg", bkgImg);

  }, [themeColor]);

  return (
    <HashRouter>
      <div className="wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          {routeComponents}
        </Suspense>
      </div>
      {/* <Redirect to = '/login'></Redirect> */}
      <div className="footer text-right">
        &copy; {new Date().getFullYear()} Copyright: Powered by ZShip
        <select name="color" className="float-right" ref={selectRef} value={themeColor} onChange={(event) => updateColorTheme(event)}>
          <option value="purple">Purple</option>
          <option value="green">Green</option>
          <option value="red">Red</option>
          <option value="blue">Blue</option>
        </select>
      </div>
    </HashRouter>
  );
}
export default App;
